import React from 'react';
import { Table, Button } from 'antd';
import "./MainTable.css"

const MainTable = ({nestedData}) => {
    console.log(nestedData)
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sNo',
            key: 'sNo',
        },
        {
            title: 'Registration Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'KYC Status',
            dataIndex: 'kycVerified',
            key: 'kycVerified',
            render: (text, record) => {
                return record.kycVerified === 0
                    ? "Not Submitted"
                    : record.kycVerified === 1
                    ? "Pending"
                    : record.kycVerified === 2
                    ? "Approved"
                    : "Rejected";
            },
        },
        {
            title: 'Total Investment',
            dataIndex: 'invested_amount',
            key: 'invested_amount',
        },
    ];

    return (
        nestedData.length != 0 &&
        <Table
            columns={columns}
            dataSource={nestedData}
            expandable={{
                expandedRowRender: (record) => (
                    <Table
                        columns={columns}
                        dataSource={record.children}
                        pagination={false}
                        showHeader={true}
                    />
                ),
                rowExpandable: (record) => record.children.length > 0,
            }}
            pagination={false}
        />
    );

}
export default MainTable;
